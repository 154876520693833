import { ProfileStore } from '~/stores/profile'
import { PxApi } from '~/composables/PxApi'
import { DefaultDates } from '~/composables/DefaultDates'
import { CarrierFootprint, Gender, PlanLetter, PlanType, RequestUserType } from '~/generated/api-clients-generated'
import dayjs from 'dayjs'
import { SessionStore } from '~/stores/session'

export default class CarrierFootprints {
  static use() {
    const session = SessionStore.use()
    const profile = ProfileStore.use()

    const { createQuotingClient } = PxApi.use()
    const client = createQuotingClient()

    const { startDate, planYear } = DefaultDates.use(session.planYear!)


    async function refreshMaPdpFootprint() {
      const footprint = await client.footprint({
        year: planYear.value,
        effectiveDate: startDate.value.toDate(),
        zip: profile.location!.zipCode!,
        county: profile.location!.fipsCountyCode!,
        state: profile.location!.stateCode!,
        types: [PlanType.MAPD, PlanType.PDP],
        snpTypes: []
      })

      session.maPdpFootprint = footprint
    }

    async function refreshMedigapFootprint() {
      const footprint = await client.medigapFootprint({
        zipCode: profile.location?.zipCode ?? undefined,
        gender: profile.gender == Gender.Male ? 'male' : 'female',
        age: _isNil(profile.dateOfBirth) ? undefined : dayjs().diff(profile.dateOfBirth, 'year'),
        isTobaccoUser: profile.isTobaccoUser,
        effectiveDate: startDate.value.toDate()
      })

      const mapped = footprint.data?.map(x => ({
        carrierId: x.carrierId,
        carrierKey: x.carrierKey,
        carrierName: x.carrierName,
        userType: RequestUserType.Customer, // ???
        planTypes: [
          {
            type: PlanType.GAP,
            count: x.plans?.length,
            filterKeys: x.filterKeys,
            planLetters: x.plans?.map(p => ({
              type: p.type,
              annualRates: p.annualRates,
              monthlyRates: p.monthlyRates
            } as PlanLetter))
          }]

      } as CarrierFootprint))
      
      session.gapFootprint = mapped
    }
    
    return {
      refreshMaPdpFootprint,
      refreshMedigapFootprint
    }
  }



  static async refresh() {
    const { refreshMaPdpFootprint, refreshMedigapFootprint } = CarrierFootprints.use()
    await refreshMaPdpFootprint()
    await refreshMedigapFootprint()
  }
}