import { SessionStore } from '~/stores/session'
import { usePageLeave, whenever } from '@vueuse/core'

export type ExitIntentContext = 'global' | 'application'

export class ExitIntent {
  static async use(context: ExitIntentContext, dialogId: string) {
    const { hide, show } = DialogController.use()
    const { track } = Analytics.use()
    const { getComponentContent } = Cms.use()

    const { content } = await getComponentContent(`ExitIntent${_startCase(context)}`)

    const options = computed(() =>
      Object.keys(content.value.ext)
        .filter((k) => k.endsWith('option'))
        .map((k) => ({
          value: k,
          text: content.value.ext[k]
        }))
    )

    const selections = ref<string[]>([])
    const feedback = ref<string | null>(null)
    const followUpModalShown = ref<string | null>(null)
    const followUpModalContent = ref<Object>({})
    const showCarrierInput = ref<boolean>(false)
    const carrierInput = ref<string | null>(null)
    const showThankYou = ref<boolean>(false)

    const { getBaseTrackingData } = ExitIntent.useTrackingData(context)

    const determineFollowUpModalContent = () => {
      const followUpModalOptions = content.value?.data?.follow_up_modals
      const followUpModalKeys = Object.keys(followUpModalOptions)

      const matchingSelections = selections.value.filter((selection) =>
        followUpModalKeys.includes(selection)
      )

      if (!matchingSelections.length) return

      const selectedKey = computed(() => {
        switch (true) {
          case matchingSelections.includes('no_carrier_option'):
            return 'no_carrier_option'
          case matchingSelections.length > 1:
            return 'has_questions_option'
          default:
            return matchingSelections[0]
        }
      })

      if (selectedKey.value === 'no_carrier_option') showCarrierInput.value = true

      followUpModalContent.value = followUpModalOptions[selectedKey.value]
      followUpModalShown.value = selectedKey.value
      show('exit-follow-up-modal')
    }

    const onViewed = () => {
      track('ExitSurveyView', {
        ...getBaseTrackingData(),
        content: options.value
      })
    }

    const onSubmit = () => {
      determineFollowUpModalContent()

      track('ExitSurveyResponse', {
        ...getBaseTrackingData(),
        content: options.value,
        selections: selections.value,
        openFeedback: feedback.value,
        followUpModalShown: followUpModalShown.value,
        carrierInput: carrierInput.value
      })

      hide(dialogId)

      if (carrierInput.value) {
        showThankYou.value = true
      }
    }

    return {
      onViewed,
      onSubmit,
      options,
      selections,
      feedback,
      showCarrierInput,
      carrierInput,
      content,
      followUpModalContent,
      showThankYou
    }
  }

  static useTriggers(context: ExitIntentContext, dialogId: string) {
    const session = SessionStore.use()
    const { flag } = FeatureFlags.use()
    const { show } = DialogController.use()
    const { isMobile } = Breakpoints.use()
    const hasLeft = usePageLeave()

    const enabledContexts = computed(() => flag<ExitIntentContext[]>('exit-intent').value)
    const shouldSurvey = computed(
      () => enabledContexts.value.includes(context) && !session.exitIntentSurveyed.includes(context)
    )

    const onExitIntent = () => {
      if (shouldSurvey.value) {
        show(dialogId)
        session.exitIntentSurveyed.push(context)
      }
    }

    if (!isMobile.value) {
      whenever(hasLeft, () => {
        onExitIntent()
      })
    }

    return {
      shouldSurvey,
      onExitIntent
    }
  }

  static useTrackingData(context: ExitIntentContext) {
    const { isMobile } = Breakpoints.use()

    const getBaseTrackingData = () => ({
      trigger: isMobile ? 'back' : 'mouse',
      exitSurveyType: context === 'application' ? 'application' : 'quotes'
    })

    return {
      getBaseTrackingData
    }
  }
}
