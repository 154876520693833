import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'

export default class CameInForElectionPeriodNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()

    return this.yesNo(['oep', 'aep'].includes(session.whatBringsYouIn.selection))
  }
}
