import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1doCGnr5OF from "/app/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import _0_MetaPlugin_client_cLwzWHsk0e from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/0.MetaPlugin.client.ts";
import _0_RootPlugin_rcGFwgvhuM from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/0.RootPlugin.ts";
import _1_RudderstackPlugin_client_ePizPXuKAl from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/1.RudderstackPlugin.client.ts";
import ChatPlugin_QKMr985CAf from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/ChatPlugin.ts";
import ModalPlugin_uPFmUWjqkb from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/ModalPlugin.ts";
import SplidePlugin_client_YGIf1FVNHZ from "/app/node_modules/@healthpilot/nuxt-base-layer/plugins/SplidePlugin.client.ts";
import _00_ConsolePlugin_client_SpTE0IOx9o from "/app/plugins/00.ConsolePlugin.client.ts";
import _01_OpenReplayPuglin_client_YrcFik6t0V from "/app/plugins/01.OpenReplayPuglin.client.ts";
import FormattingPlugin_OiNsjiF08L from "/app/plugins/FormattingPlugin.ts";
import LaunchDarklyPlugin_client_f8xU13Q4DH from "/app/plugins/LaunchDarklyPlugin.client.ts";
import SentryPlugin_kwnn0UYlRB from "/app/plugins/SentryPlugin.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_1doCGnr5OF,
  _0_MetaPlugin_client_cLwzWHsk0e,
  _0_RootPlugin_rcGFwgvhuM,
  _1_RudderstackPlugin_client_ePizPXuKAl,
  ChatPlugin_QKMr985CAf,
  ModalPlugin_uPFmUWjqkb,
  SplidePlugin_client_YGIf1FVNHZ,
  _00_ConsolePlugin_client_SpTE0IOx9o,
  _01_OpenReplayPuglin_client_YrcFik6t0V,
  FormattingPlugin_OiNsjiF08L,
  LaunchDarklyPlugin_client_f8xU13Q4DH,
  SentryPlugin_kwnn0UYlRB
]