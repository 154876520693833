import { PlanType } from '~/generated/api-clients-generated'
import { EngineStore } from '~/stores/engine'
import type { CamelToSnakeCase } from '~/types/util'

export type QuotingFeature =
  | 'collapsedQuotes'
  | 'globalAdd'
  | 'planFinder'
  | 'pushToCsAgentProfiles'
  | 'pushToCsAboveFoldCall'
  | 'limitedChoice'
  | 'surfacedCompare'
  | 'emptyAddNeeds'
  | 'hidePlanTags'
  | 'quoteCard'
export type QuotingFeatureConfigKey = CamelToSnakeCase<QuotingFeature>

export default class QuotingConfig {
  static use() {
    const { flag } = FeatureFlags.use()

    const engine = EngineStore.use()

    const mapdConfig = computed(
      () => flag<Record<QuotingFeatureConfigKey, any>>('mapd-quoting-config').value
    )
    const pdpConfig = computed(
      () => flag<Record<QuotingFeatureConfigKey, any>>('pdp-quoting-config').value
    )

    const typeConfig = computed(() =>
      engine.selectedPlanType === PlanType.PDP ? pdpConfig.value : mapdConfig.value
    )

    const globalAddShouldStick = computed(
      () =>
        (engine.params.doctors?.length ?? 0) < 1 &&
        (engine.params.rxs?.length ?? 0) < 1 &&
        (engine.params.additionalBenefits?.length ?? 0) < 1
    )

    const quoteCardVariants = computed(() => ({
      costsAndNeeds: defineAsyncComponent(
        () => import('~/components/quote/card/variants/CostsAndNeeds.vue')
      ),
      costsPreview: defineAsyncComponent(
        () => import('~/components/quote/card/variants/CostsPreview.vue')
      ),
      highlightingPlanFit: defineAsyncComponent(
        () => import('~/components/quote/card/variants/HighlightingPlanFit.vue')
      ),
      showMeThePlanFit: defineAsyncComponent(
        () => import('~/components/quote/card/variants/ShowMeThePlanFit.vue')
      )
    }))

    const config = computed<Record<QuotingFeature, any>>(() =>
      Object.keys(typeConfig.value).reduce(
        (agg, curr) => ({
          ...agg,
          [_camelCase(curr)]: typeConfig.value[curr as QuotingFeatureConfigKey]
        }),
        {} as Record<QuotingFeature, any>
      )
    )

    const quotingConfig = computed(() => ({
      ...config.value,
      globalAddShouldStick: globalAddShouldStick.value,
      quoteCardVariants: quoteCardVariants.value,
      quoteCardVariant: quoteCardVariants.value[config.value.quoteCard]
      //quoteCardVariant: quoteCardVariants.value['showMeThePlanFit']
    }))

    return {
      quotingConfig
    }
  }
}
