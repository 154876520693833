import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'

export class NeedsOutboundContactInfoNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const skipCookie = useCookie('skip-outbound-lead')
    const profile = ProfileStore.use()
    const { isAuthenticated } = Auth.use()

    return this.yesNo(
      isAuthenticated.value
        ? !profile.hasExt('flow_traversed', 'outbound_lead')
        : _isNil(skipCookie.value)
    )
  }
}
