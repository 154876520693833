import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'

export default class ShowPlanLetterRecNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()

    return this.yesNo(flag<boolean>('show-plan-letter-rec').value)
  }
}
