import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { SiteStore } from '~/stores/site'

export default class WhatBringsYouInEnabledNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const site = SiteStore.use()

    const wbyiJourneys = flag<number[]>('what-brings-you-in')

    return this.yesNo(wbyiJourneys.value.includes(site.journey.id ?? 4))
  }
}
