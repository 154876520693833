<template>
  <PxStickyContainer
    v-if="!hideHelpBar"
    position="bottom"
    class="flex-col bg-primary-100 sm:h-20 md:border-t-2 md:border-primary-300 md:border-opacity-50"
    :class="{ 'rounded-t-modal bg-white md:rounded-t-none md:bg-primary-100': showAgentPhone }"
  >
    <div class="flex h-full items-center justify-center px-3">
      <SitePhoneAgentBubble
        v-if="showAgentPhone"
        variant="sticky-footer"
        source="StickyFooterFlowPages"
      />

      <!--    <HpButton-->
      <!--      id="sticky-help-btn"-->
      <!--      class="h-fit max-w-56"-->
      <!--      :class="{ 'hidden md:block': showAgentPhone }"-->
      <!--      @click="showChat('amazon-connect')"-->
      <!--      variant="secondary"-->
      <!--    >-->
      <!--      <HpIcon name="help" class="mr-1" />-->
      <!--      {{ content.ext.help_text }}-->
      <!--    </HpButton>-->

      <ConnectChatWidget
        v-if="!isQa"
        :hide-launcher="false"
        icon="help"
        :launcher-text="content.ext.help_text"
        :class="['h-fit max-w-56', { 'hidden md:block': showAgentPhone }]"
      />
    </div>
    <SiteWhatIsHealthpilotButton variant="bar" class="block sm:hidden" />
  </PxStickyContainer>
</template>

<script lang="ts" setup>
  import { PlanType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'

  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('QuotesStickyActions', 'BottomBar')

  const { flag } = FeatureFlags.use()
  const showAgentPhone = computed(() => flag<boolean>('show-agent-phone').value)

  const { isIndex, path, journeyId } = AppRoute.use()
  const engine = EngineStore.use()
  const { show: showChat } = ChatController.use()

  const hideHelpBar = computed(
    () =>
      (path.value == '/quotes' && engine.selectedPlanType !== PlanType.GAP) ||
      path.value == '/compare' ||
      path.value.includes('/details') ||
      path.value.includes('/apply') ||
      path.value == '/dashboard' ||
      path.value == '/standby' ||
      path.value == '/confirm'
  )

  const { isQa } = DevModeHelpers.use()
</script>

<style scoped></style>
