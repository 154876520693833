import { defineStore } from 'pinia'
import type { Guid } from '~/types/util'
import type {
  Attribution,
  DataConsent,
  EmailAddress,
  Location,
  MedicaidInfo,
  MedicareCard,
  MobilePhone,
  NeedsAssessment,
  Profile,
  ProfileBase,
  ProfileExt,
  ScheduledCall
} from '~/generated/api-clients-generated'
import { AdditionalBenefitType, Gender } from '~/generated/api-clients-generated'
import { SessionStore } from '~/stores/session'
import { RxStore } from '~/stores/rx'
import { ProviderStore } from '~/stores/provider'
import { PharmacyStore } from '~/stores/pharmacy'
import { ApplicationStore } from '~/stores/applications'
import { IepStore } from '~/stores/iep'
import { PlanCheckupStore } from '~/stores/planCheckup'

export enum StateAssistance {
  Unknown = 0,
  Eligible = 1,
  NotEligible = 2
}

export class ProfileStore {
  private static _dirty: boolean = false
  private static _trackChanges: boolean = false

  static use = defineStore('profile', () => {
    const sync = ref<boolean>(false)

    const id = ref<Guid | null>(null)
    const gender = ref<Gender | null>(null)
    const dateOfBirth = ref<Date | string | null>(null)
    const firstName = ref<string | null>(null)
    const lastName = ref<string | null>(null)
    const middleName = ref<string | null>(null)
    const isTobaccoUser = ref<boolean | null>(null)
    const emailAddress = ref<EmailAddress>({} as EmailAddress)
    const mobilePhone = ref<MobilePhone>({} as MobilePhone)
    const medicareCard = ref<MedicareCard>({} as MedicareCard)

    const location = ref<Location>({} as Location)
    const needsAssessment = ref<NeedsAssessment>({} as NeedsAssessment)

    const medicaidInfo = ref<MedicaidInfo>({} as MedicaidInfo)

    const guaranteedIssueExpiration = ref<Date | null>(null)

    const scheduledCalls = ref<Array<ScheduledCall>>([])

    const stateAssistanceEligibility = computed(() =>
      medicaidInfo.value?.hasMedicaid !== null
        ? medicaidInfo.value?.hasMedicaid
          ? StateAssistance.Eligible
          : StateAssistance.NotEligible
        : StateAssistance.Unknown
    )

    const engineId = ref<string | null>(null)

    const consents = ref<Array<DataConsent>>([])
    const attributions = ref<Array<Attribution>>([])

    const ext = ref<Array<ProfileExt>>([])

    function $reset() {
      console.log('PROFILE $reset')
      init(null)
    }

    async function setEngineId(newEngineId: Guid) {
      engineId.value = newEngineId
      await client.value.setEngineId(id.value!, { value: newEngineId })
    }

    const client = computed(() => {
      const { createProfilesClient } = PxApi.use()
      return createProfilesClient()
    })

    async function load(id: Guid) {
      try {
        const profile = await client.value.get(id)
        init(profile)
      } catch (ex) {
        console.log('Could not locate profile', id)
      }
    }

    function init(data: Profile | null) {
      id.value = data?.id ?? null
      location.value = data?.location ?? ({} as Location)
      needsAssessment.value = data?.needsAssessment ?? ({} as NeedsAssessment)
      medicaidInfo.value = data?.medicaidInfo ?? ({} as MedicaidInfo)
      gender.value = data?.gender ?? null
      dateOfBirth.value = data?.dateOfBirth ?? null
      firstName.value = data?.firstName ?? null
      lastName.value = data?.lastName ?? null
      middleName.value = data?.middleName ?? null
      isTobaccoUser.value = data?.isTobaccoUser ?? null
      emailAddress.value = data?.emailAddress ?? ({} as EmailAddress)
      mobilePhone.value = data?.mobilePhone ?? ({} as MobilePhone)
      medicareCard.value = data?.medicareCard ?? ({} as MedicareCard)
      engineId.value = data?.engineId ?? null
      savedPlans.value = _mapKeys(data?.savedPlans, (_, k) => +k)
      guaranteedIssueExpiration.value = data?.guaranteedIssueExpiration ?? null
      attributions.value = data?.attributions ?? []
      consents.value = data?.consents ?? []
      scheduledCalls.value = data?.scheduledCalls ?? []
      ext.value = data?.ext ?? []

      sync.value = true

      ApplicationStore.init(data?.applications)
      RxStore.init(data?.rxs)
      ProviderStore.init(data?.providers)
      PharmacyStore.init(data?.pharmacies)
      IepStore.init(data?.iepContext)
      PlanCheckupStore.init(data?.planCheckups)

      if (!_isNil(id.value)) {
        Integrations.setIdentity(id.value)
      }
    }

    async function _create() {
      const session = SessionStore.use()
      const data = {
        id: id.value,
        dateOfBirth: dateOfBirth.value,
        gender: gender.value,
        location: location.value,
        needsAssessment: needsAssessment.value,
        firstName: firstName.value,
        lastName: lastName.value,
        middleName: middleName.value,
        emailAddress: emailAddress.value,
        mobilePhone: mobilePhone.value,
        medicareCard: medicareCard.value,
        medicaidInfo: medicaidInfo.value,
        attributions: !_isEmpty(session.params) ? [{ params: session.params }] : []
      } as Profile

      const profile = await client.value.create(data)
      init(profile)
    }

    async function _update() {
      const data = {
        id: id.value,
        dateOfBirth: dateOfBirth.value,
        gender: gender.value,
        location: location.value,
        needsAssessment: needsAssessment.value,
        firstName: firstName.value,
        lastName: lastName.value,
        middleName: middleName.value,
        isTobaccoUser: isTobaccoUser.value,
        emailAddress: emailAddress.value,
        mobilePhone: mobilePhone.value,
        medicareCard: medicareCard.value,
        medicaidInfo: medicaidInfo.value,
        attributions: [] // Intentionally empty; attributions are handled on profile create and sign-in
      } as ProfileBase

      await client.value.update(data)
    }

    const savedPlans = ref<{ [key: number]: string[] }>({})

    function addSavedPlan(year: number, planId: string) {
      savedPlans.value = {
        ...savedPlans.value,
        [year]: [...(savedPlans.value[year] ?? []), planId]
      }

      const { createProfilesClient } = PxApi.use()

      const client = createProfilesClient()

      const _ = client.updateSavedPlans(id.value!, savedPlans.value)
    }

    function removeSavedPlan(year: number, planId: string) {
      savedPlans.value = {
        ...savedPlans.value,
        [year]: [..._without(savedPlans.value[year], planId)]
      }

      const { createProfilesClient } = PxApi.use()

      const client = createProfilesClient()

      const _ = client.updateSavedPlans(id.value!, savedPlans.value)
    }

    function removeAdditionalBenefit(additionalBenefit: AdditionalBenefitType) {
      if (!needsAssessment.value || !needsAssessment.value.additionalBenefits) return
      needsAssessment.value.additionalBenefits = needsAssessment.value.additionalBenefits.filter(
        (item) => item !== additionalBenefit
      )

      ProfileStore._dirty = true
    }

    function setAdditionalBenefitToNone() {
      if (!needsAssessment.value || !needsAssessment.value.additionalBenefits) return

      needsAssessment.value.additionalBenefits = []
      needsAssessment.value.additionalBenefits.push(AdditionalBenefitType.None)

      ProfileStore._dirty = true
    }

    async function addDataConsent(payload: DataConsent) {
      await client.value.addDataConsent(payload)
      consents.value.push(payload)
    }

    async function addScheduledCall(email: string, startTime: Date, source: 'calendly', data: any) {
      const item = {
        startDateTime: startTime,
        source: source,
        data: data
      }

      const callId = await client.value.saveScheduledCall(id.value!, { email: email, ...item })
      scheduledCalls.value.push({ id: callId, ...item })
    }

    async function addExt(newExt: ProfileExt) {
      await client.value.addExt(id.value!, newExt)

      newExt.createdAt = now().toDate()
      ext.value.push(newExt)
    }

    function hasExt(key: string, value: any) {
      return !!findExt(key).find((x) => x.value == value)
    }
    function findExt<T = any>(key: string): Array<{ value?: T; createdAt?: Date }> {
      const matches = ext.value.filter((x) => x.key == key)
      return (
        matches?.map((x) => {
          const extMapped = {
            createdAt: x.createdAt,
            value: x.value as T
          }

          if (!_isNil(x.value) && !_isNil(x.valueType)) {
            switch (x.valueType) {
              case 'json':
                extMapped.value = JSON.parse(x.value) as T
                break
              case 'number':
                extMapped.value = Number(x.value) as T
                break
            }
          }

          return extMapped
        }) ?? []
      )
    }

    return {
      sync,
      id,
      location,
      gender,
      dateOfBirth,
      needsAssessment,
      medicaidInfo,
      stateAssistanceEligibility,
      firstName,
      lastName,
      middleName,
      isTobaccoUser,
      emailAddress,
      mobilePhone,
      medicareCard,
      guaranteedIssueExpiration,
      scheduledCalls,
      load,
      _create,
      _update,
      init,
      savedPlans,
      addSavedPlan,
      removeSavedPlan,
      setEngineId,
      engineId,
      removeAdditionalBenefit,
      setAdditionalBenefitToNone,
      addDataConsent,
      consents,
      addScheduledCall,
      addExt,
      findExt,
      hasExt,
      $reset
    }
  })

  static async save(force: boolean = false) {
    if (ProfileStore._dirty || !ProfileStore._trackChanges || force) {
      const store = ProfileStore.use()

      if (!_isNil(store.id)) {
        await store._update()
        ProfileStore._dirty = false
      }
    }
  }

  static async createIfEmpty() {
    const profile = ProfileStore.use()
    const session = SessionStore.use()

    if (_isNil(profile.id)) {
      await profile._create()
      session.profileId = profile.id
    }
  }

  static trackChanges() {
    ProfileStore._trackChanges = true
    const profile = ProfileStore.use()
    let actionExecuting = false
    profile.$onAction(({ after }) => {
      actionExecuting = true

      after(() => {
        actionExecuting = false
      })
    })

    profile.$subscribe((mutation) => {
      if (mutation.type === 'direct' && !actionExecuting) {
        ProfileStore._dirty = true
      }
    })
  }

  static reset() {
    const profile = ProfileStore.use()
    profile.$reset()
  }
}
