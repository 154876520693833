<template>
  <article :class="bubbleComputedClasses">
    <picture :class="imageComputedClasses">
      <source
        :srcset="`${url}/assets/4da3f0c2-d7cd-4394-ba2a-e5a866488dea.png?width=150&fit=cover&format=webp`"
        type="image/webp"
        width="100"
        height="100"
      />
      <img
        :src="`${url}/assets/4da3f0c2-d7cd-4394-ba2a-e5a866488dea.png?width=150&fit=cover`"
        alt="Medicare support expert, Grace Kelly."
        width="100"
        height="100"
        fetchpriority="high"
      />
    </picture>

    <HpAnchor
      :href="`tel:${phoneNumber ?? defaultNumber}`"
      :class="phoneComputedClasses"
      :event-data="analyticsProperties"
    >
      {{ DisplayPhoneNumber(String(phoneNumber ?? defaultNumber)) }}
      | TTY 711
      <small
        class="-mt-1 block text-center text-sm tracking-normal"
        :class="{ 'mt-0': variant === 'lg' }"
      >
        {{ hours }}
      </small>
    </HpAnchor>

    <HpButton
      v-if="variant === 'sticky-footer'"
      id="sticky-help-chat"
      @click="showChat('amazon-connect')"
      variant="secondary"
      class="w-[4.5rem]"
    >
      <span class="flex flex-col items-center">
        <span class="text-lg font-bold leading-tight">Agent Chat</span>
      </span>
    </HpButton>
  </article>
</template>

<script lang="ts" setup>
  import DisplaySupportHours from '~/composables/DisplaySupportHours'
  import { DisplayPhoneNumber } from '~/composables/DisplayPhoneNumber'

  export type Variant = 'sticky-footer' | 'header' | 'lg'

  const props = defineProps({
    source: {
      type: String
    },
    variant: {
      type: String as PropType<Variant>,
      default: 'lg'
    }
  })

  const url = useRuntimeConfig().public.directusUrl
  const { show: showChat } = ChatController.use()

  const { phoneNumber, defaultNumber } = await SupportNumbers.use()
  const { hours } = await DisplaySupportHours.use()

  const analyticsProperties = {
    source: props.source
  }

  const bubbleComputedClasses = computed(() => [
    'bubble',
    {
      'bubble-header': props.variant === 'header',
      'bubble-sticky-footer': props.variant === 'sticky-footer'
    }
  ])

  const imageComputedClasses = computed(() => [
    'agent-picture',
    {
      'agent-picture-header': props.variant === 'header',
      'agent-picture-sticky-footer': props.variant === 'sticky-footer'
    }
  ])

  const phoneComputedClasses = computed(() => [
    'fluid-phone',
    {
      'fluid-phone-header': props.variant === 'header',
      'fluid-phone-sticky-footer': props.variant === 'sticky-footer'
    }
  ])
</script>

<style scoped>
  .bubble {
    @apply mx-auto mt-3 flex min-h-20 w-fit items-center rounded-full bg-white px-5 shadow-menu;

    &.bubble-header {
      @apply mt-1 h-[3.5rem] min-h-0 pl-3 pr-4;
    }

    &.bubble-sticky-footer {
      @apply mt-0 !flex w-full max-w-lg justify-between rounded-none px-1 shadow-none xs:px-0 md:!hidden;
    }

    &.hide-container {
      @apply shadow-none;
    }
  }

  .agent-picture {
    @apply -mb-0.5 -mt-3.5 hidden self-end overflow-hidden rounded-l-2xl sm:block;

    &.agent-picture-header {
      @apply w-[4.25rem] rounded-l-[0.85rem];
    }

    &.agent-picture-sticky-footer {
      @apply -mr-2 hidden w-full min-w-20 max-w-24 rounded-none xs:block;
    }
  }

  .fluid-phone {
    /* fallback font sizes for browsers that don't support clamp */
    @apply whitespace-nowrap pb-0.5 text-4xl font-bold tracking-tight no-underline xs:text-2xl;
    font-size: clamp(1.5rem, 7.5vw, 2.063rem);
  }

  .fluid-phone-header {
    /* fallback font sizes for browsers that don't support clamp */
    @apply py-1 text-xl;
    font-size: clamp(1.25rem, 2.75vw, 1.5rem);
  }

  .fluid-phone-sticky-footer {
    /* fallback font sizes for browsers that don't support clamp */
    @apply text-2xl !font-bold xs:text-2xl;
    @apply mr-2 text-[clamp(1.25rem,_7vw,_1.75rem)] xs:text-[clamp(1.25rem,_5.5vw,_1.75rem)];
  }

  @media screen and (max-width: 365px) {
    .pricing-item-label {
      @apply text-[1.05rem] !important;
    }
  }
</style>
