<template>
  <template v-if="showWhatIsHealthpilot">
    <HpButton
      v-bind="$attrs"
      @click="show('what-is-healthpilot')"
      variant="minimal"
      :class="computedClasses"
      type="button"
    >
      {{ content?.data?.button_label }}
    </HpButton>
  </template>
</template>

<script lang="ts" setup>
  export type ButtonVariant = 'button' | 'bar'

  const props = defineProps({
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'button'
    }
  })

  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('WhatIsHealthpilot')

  const { show } = DialogController.use()

  const { flag } = FeatureFlags.use()
  const showWhatIsHealthpilot = computed(() => flag<boolean>('what-is-healthpilot-button').value)

  const computedClasses = computed(() => [
    'btn',
    {
      button: props.variant === 'button',
      bar: props.variant === 'bar'
    }
  ])
</script>

<style scoped>
  .btn {
    @apply bg-sky-800 text-sm font-semibold text-white transition-all hover:brightness-110;

    &.button {
      @apply min-h-fit w-fit rounded-full px-2 pb-[1px] leading-snug hover:scale-[1.005];
    }

    &.bar {
      @apply min-h-0 w-full rounded-none py-1 text-base font-semibold underline underline-offset-2;
    }
  }
</style>
