import { defineStore } from 'pinia'
import type { Guid } from '~/types/util'
import { PxApi } from '~/composables/PxApi'
import type {
  CarrierFootprint,
  CountyData,
  GooglePlacesCityPrediction,
  Pharmacy,
  PlanTypeRecommendation
} from '~/generated/api-clients-generated'
import {
  PlanType,
  PlanTypeRecommendationType,
  WaypointResult
} from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { QuoteFilters, QuoteSortOrder, QuoteSortTracker } from '~/composables/QuoteUtils'
import { EngineStore } from '~/stores/engine'
import type { IDataImportSession } from '~/models/DataImportSession'
import type { PlandalfPreference, PlandalfRecentlyViewed } from '~/models/PlandalfModels'
import type { PlanList } from '~/composables/PlanLists'
import { ExitIntentContext } from '~/composables/ExitIntent'

export class SessionStore {
  private static _empty: any | null = null
  private static _resetting: boolean = false

  static use = defineStore('session', () => {
    const sync = ref<boolean>(false)

    const profileId = ref<Guid | null>(null)

    const planYear = ref<number | null>(null)

    const zipCode = ref<string | null>(null)
    const counties = ref<Array<CountyData>>([])
    const hasMedicare = ref<boolean | null>(null)

    const adventureType = ref<WaypointResult | null>(null)

    const planType = ref<PlanType | null>()
    const selectedPlanTypeRec = ref<PlanTypeRecommendationType | null>()

    const _quoteSortTracker = ref<QuoteSortTracker>(QuoteSortTracker.default())
    const quoteSortOrder = computed({
      get() {
        if (_isNil(planType.value)) return 'default'
        return _quoteSortTracker.value[planType.value]
      },
      set(value: QuoteSortOrder) {
        if (_isNil(planType.value)) return
        _quoteSortTracker.value[planType.value] = value
      }
    })

    const quoteFilters = ref<QuoteFilters>(QuoteFilters.empty(planYear.value))
    const currentQuoteId = ref<string | null>(null)

    const plandalfPreferences = ref<PlandalfPreference[]>([])

    const plandalfRecentlyViewedMapd = ref<PlandalfRecentlyViewed>({})
    const plandalfRecentlyViewedPdp = ref<PlandalfRecentlyViewed>({})

    const doctorSearchLocation = ref<GooglePlacesCityPrediction | null>(null)

    const importPref = ref<boolean | null>(null)
    const dataImport = ref<IDataImportSession>({})

    const associatedPharmacy = ref<Pharmacy | null>(null)
    const suppressPharmacyFilterConfirmation = ref<boolean | null>(null)

    const currentApplicationId = ref<Guid | null>(null)
    const pendingApplicationData = ref<any | null>(null)
    const planTypeRecommendations = ref<PlanTypeRecommendation | null>(null)

    // TODO: Refactor this :)
    const carrierFootprint = computed(() => {
      const maPdpExists = !!maPdpFootprint.value?.length
      const gapExists = !!gapFootprint.value?.length
      if (!maPdpExists && !gapExists) return []
      if (!maPdpExists) return gapFootprint.value
      if (!gapExists) return maPdpFootprint.value

      const uniqCarriers = _uniq([
        ...gapFootprint.value.map((x) => x.carrierKey),
        ...maPdpFootprint.value.map((x) => x.carrierKey)
      ])

      const merged: CarrierFootprint[] = []

      for (const carrier of uniqCarriers) {
        const maPdp = _cloneDeep(maPdpFootprint.value).find((x) => x.carrierKey == carrier)
        const gap = _cloneDeep(gapFootprint.value).find((x) => x.carrierKey == carrier)

        if (!maPdp) {
          merged.push(gap)
          continue
        }
        if (!gap) {
          merged.push(maPdp)
          continue
        }

        merged.push({
          carrierId: maPdp.carrierId,
          carrierKey: maPdp.carrierKey,
          carrierName: maPdp.carrierName,
          disclosures: maPdp.disclosures,
          logos: maPdp.logos,
          userType: maPdp.userType,
          planTypes: [...maPdp.planTypes, ...gap.planTypes]
        })
      }

      return merged
    })

    const maPdpFootprint = ref<Array<CarrierFootprint>>([])
    const gapFootprint = ref<Array<CarrierFootprint>>([])

    const availablePlanTypes = computed(() =>
      _uniq(carrierFootprint.value.flatMap((x) => x.planTypes!.map((y) => y.type!)))
    )

    function existsInFootprint(carrierKey: string, planType?: PlanType) {
      const carrier = carrierFootprint.value?.find((x) => x.carrierKey == carrierKey)

      if (planType) {
        return !!carrier?.planTypes?.find((x) => x.type == planType)
      }

      return !!carrier
    }

    // captured query params
    const params = ref<any>({})

    // evaluated feature flags
    const flags = ref<any>({})

    const comparedPlans = ref<string[]>([])

    const quickViewSelections = ref(['Monthly Premiums', 'Max Out of Pocket', 'Medical Deductible'])

    const getHelpType = ref<string | null>(null)

    const authBag = ref<any>({})

    const userAgent = ref<string | null>(null)

    const deviceId = ref<string | null>(null)
    const unreadMessages = ref<number>(0)

    const prevPage = ref<string | null>(null)

    const idleTriggeredModals = ref<string[]>([])

    function captureFlag(key: string, value: any) {
      flags.value[key] = value
      SessionStore._saveSession()
    }

    const editingIepContext = ref<boolean>(false)

    const modifiedIepContext = ref<any>({})

    const medSuppCallScheduled = ref<boolean>(false)
    const client = computed(() => {
      const { createProfilesClient } = PxApi.use()
      return createProfilesClient()
    })

    async function getPlanTypeRecommendations() {
      const profile = ProfileStore.use()
      try {
        planTypeRecommendations.value = await client.value.getPlanTypeRecommendations({
          profileId: profile.id!,
          availablePlanTypes: availablePlanTypes.value
        })
      } catch (err) {
        console.error('Error in getPlanTypeRecommendations', err)
      }
    }

    const planList = ref<PlanList>('all')

    const currentPlanCarrier = ref<{ value: string; text: string } | null>(null)
    const currentPlanId = ref<string | null>(null)

    const pushToSignInExisting = ref<boolean>(false)

    const wasProactive = ref<boolean>(false)
    const wasProactiveOnApp = ref<boolean>(false)

    const pushedToSave = ref<Record<string, boolean>>({})
    const exitIntentSurveyed = ref<ExitIntentContext[]>([])

    const stickyContactCardViewed = ref<boolean>(false)

    const quotesHidden = ref<boolean | undefined>(undefined)
    const showQuotesBelowThreshold = ref<boolean | undefined>(undefined)

    const applicationMedicareCardModalShown = ref<boolean>(false)

    const whatBringsYouIn = ref<Record<string, any>>({})

    const outboundLeadPhone = ref<string | null | undefined>(null)

    return {
      sync,
      profileId,
      zipCode,
      counties,
      hasMedicare,
      adventureType,
      planYear,
      planType,
      selectedPlanTypeRec,
      quoteFilters,
      currentQuoteId,
      plandalfPreferences,
      plandalfRecentlyViewedMapd,
      plandalfRecentlyViewedPdp,
      doctorSearchLocation,
      quoteSortOrder,
      carrierFootprint,
      maPdpFootprint,
      gapFootprint,
      params,
      flags,
      captureFlag,
      comparedPlans,
      quickViewSelections,
      getHelpType,
      authBag,
      userAgent,
      currentApplicationId,
      pendingApplicationData,
      deviceId,
      unreadMessages,
      associatedPharmacy,
      suppressPharmacyFilterConfirmation,
      importPref,
      dataImport,
      availablePlanTypes,
      prevPage,
      idleTriggeredModals,
      existsInFootprint,
      _quoteSortTracker,
      modifiedIepContext,
      editingIepContext,
      medSuppCallScheduled,
      getPlanTypeRecommendations,
      planTypeRecommendations,
      planList,
      currentPlanCarrier,
      currentPlanId,
      pushToSignInExisting,
      wasProactive,
      wasProactiveOnApp,
      pushedToSave,
      exitIntentSurveyed,
      stickyContactCardViewed,
      quotesHidden,
      showQuotesBelowThreshold,
      applicationMedicareCardModalShown,
      whatBringsYouIn,
      outboundLeadPhone
    }
  })

  static async save() {
    const store = SessionStore.use()
    const { createSessionClient } = PxApi.use()
    const client = createSessionClient()
    await client.save(store.$state)
  }

  static async load(andSave: boolean = false) {
    const store = this.use()
    const { createSessionClient } = PxApi.use()
    const client = createSessionClient()
    const { $root } = useNuxtApp()

    if (_isNil(SessionStore._empty)) {
      SessionStore._empty = {
        ...store.$state,
        ...Object.fromEntries(
          Object.entries(store.$state)
            .filter(([_, value]) => _isArray(value) || _isObject(value))
            .map(([key, value]) => [key, _isArray(value) ? [] : {}])
        )
      }
    }

    console.log('LOAD SESSION')

    const state = await client.get()
    store.$patch({
      ...state.data,
      params: {
        ...state.data.params,
        ...store.$state.params
      },
      sync: true
    })

    SessionStore._resetting = false

    const profile = ProfileStore.use()
    profile.init(state.profile!)

    if (store.profileId !== profile.id) {
      store.profileId = profile.id
    }

    if (_isNil(store.planType)) {
      store.planType = profile.needsAssessment?.planType
    }

    if (process.client && _isNil(store.userAgent)) {
      store.userAgent = window.navigator.userAgent
    }

    if (process.client && _isNil(store.deviceId)) {
      const { deviceId } = CookieJar.use()
      store.deviceId = deviceId.value
    }

    if (andSave) {
      await SessionStore.save()
    }

    $root.emit('session-ready')
  }

  static async reset() {
    const { createSessionClient } = PxApi.use()
    const client = createSessionClient()
    await client.end()

    ProfileStore.reset()
    EngineStore.reset()

    SessionStore._resetting = true
    const session = SessionStore.use()

    console.log('SessionStore.reset SessionStore._empty', SessionStore._empty)

    Object.entries(SessionStore._empty).forEach(([key, value]) => {
      if (Object.hasOwn(session, key)) {
        switch (key) {
          case 'quoteFilters':
            session[key] = QuoteFilters.empty(session.planYear)
            break
          case '_quoteSortTracker':
            session[key] = QuoteSortTracker.default()
            break
          default:
            session[key] = value
        }
      }
    })

    await SessionStore.load(true)
  }

  static autosave() {
    const session = SessionStore.use()

    session.$subscribe((mutation) => {
      if (mutation.type === 'direct' && !SessionStore._resetting) SessionStore._saveSession()
    })
  }

  private static _saveSession = _debounce(() => {
    if (process.server) return
    SessionStore.save()
  }, 1000)
}
